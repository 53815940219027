import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import { navigate } from "gatsby"
import Button from "../button-generic"
import PropTypes from "prop-types"
//import ModalForm from "../modal/modal-form"
import ButtonModal from "../buttons/button-cta-top-content"
import { Link } from "gatsby"
import Icon from "../icon"


 const ProjectItem = (props) => {

   const {
     title,
     image,
     body,
     classes,
     url,
     columns,
     buttonType,
     //formId,
     formData,
     transparent,
     totalSubministries
   } = props

   const { t } = useContext(LanguageContext)

   if(columns === 2 ){
     return (
         <div className={classes}>
          <div className="col-sm-6 p-0">
            <div className={`${transparent ? '' : 'background-beig'} ministry-image m-b-8`}>
              <img
                src={image}
                alt={title}
                className="m-r--24"
              />
            </div>
          </div>
          <div className="col-sm-6 p-t-63 p-l-0 medium-p-t-17 medium-p-l-17 small-p-t-16 small-p-l-16">
            <h2 className="title ministry-title m-b-8"> {title} </h2>
            <div className="ministry-desc">
              <h5 className="m-b-16" dangerouslySetInnerHTML={{__html: body}}/>
             </div>
             {
               buttonType === "modal"
               ?
               <ButtonModal
                title={t("Veure més")}
                backgroundimage={props?.image}
                formId="voluntariat_tramuntana"
                formData={formData}
                modalTitle=""
                modalDescription=""
                modalFooter=""
                icon="icons-32-x-32-generic-plus"
                iconSize={16}
                iconColor="#ff0"
                iconClassName="icon"
                className="r-more Normal-Bold-L---orange right p-r-0"
                />
               :
               <Link
                to={title === "Escola Bíblica Teològica" ? "/escola" : url}
                className="r-more Normal-Bold-L---orange right p-r-0"
               >
                {t("Veure més")}
                <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
               </Link>

              }
          </div>
          </div>
     )
   }
   return (
      <div className={classes}>
       <div>
         <div className={`${transparent ? '' : 'background-beig'} ministry-image m-b-8`}>
           <img
             src={image}
             alt={title}
             className="m-r--24"
           />
         </div>
       </div>
       <div className="col-md-6- m-w-507-">
         <h2 className="title ministry-title m-b-8"> {title} </h2>
         <div className="ministry-desc">
         {
           totalSubministries
           ?
           <TotalSubministries
             totalSubministries={totalSubministries}
             t={t}
             className="subministries-count"
            />
           :
           ""
          }
           <h5 className="m-b-16" dangerouslySetInnerHTML={{__html: body}}/>
          </div>
          {
            buttonType === "modal"
            ?
            <ButtonModal
             title={t("Veure més")}
             backgroundimage={props?.image}
             formId="voluntariat_tramuntana"
             formData={formData}
             modalTitle=""
             modalDescription=""
             modalFooter=""
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more Normal-Bold-L---orange right m-r-24"
             />
            :
            <Link
             to={title === "Escola Bíblica Teològica" ? "/escola" : url}
             className="r-more Normal-Bold-L---orange right m-r-24"
            >
             {t("Veure més")}
             <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
            </Link>

          }

       </div>
       </div>
   )
 }

 const TotalSubministries = (props) => {

   const { totalSubministries, t, className } = props
   if(!totalSubministries){
     return
   }
   return(
     <div className={className}>
     {
       totalSubministries === 0
       ?
       <span>{totalSubministries} {t('projectes')}</span>
       :
       totalSubministries === 1
       ?
       <span>{totalSubministries} {t('projecte')}</span>
       :
       <span>{totalSubministries} {t('projectes')}</span>
      }
      </div>
   )

 }

 /*const useDataFormModal = () => {
   return useStaticQuery(graphql`
      query serviceOpportunityQuery {
        form_visit_us: webformWebform(drupal_internal__id: {eq: "visit_us_webform"}) {
          drupal_internal__id
          langcode
          elements {
            name
            type
            attributes {
              name
              value
            }
          }
        }
      }
    `)
 }*/

 ProjectItem.propTypes = {
   comumns: PropTypes.number,
   buttonType: PropTypes.string,
   formId: PropTypes.string,
   transparent: PropTypes.bool,
   totalSubministries: PropTypes.number
 }

 ProjectItem.defaultProps = {
   comumns: 1,
   buttonType: `url`,
   formId: ``,
   transparent: false,
   totalSubministries: 0
 }

 export default ProjectItem
