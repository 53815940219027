import React, { useContext } from "react"
import PropTypes from "prop-types"
import ButtonModal from "../buttons/button-cta-top-content"
//import Button from "../button-generic"
import { LanguageContext } from "../../context"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"
import Utils from "../../utils"


const Banner = ({
    post,
    height,
    buttonBanner,
    form,
    hasButton,
    backgroundColor,
    anchorButton,
    anchorButtonText,
    secondButton,
    multipleButtons,
    buttonType,
    summaryAsBody,
    buttonLinkBanner,
    isCategory,
    isTag,
    className
  }) => {


  const { t } = useContext(LanguageContext)
  const nodeBlogPost = post?.relationships?.node__blog_post_tramuntana ?  post?.relationships?.node__blog_post_tramuntana[0] : []
  const backgroundImage =
    post?.relationships?.field_image?.image_style_uri?.wide
    ?
    post?.relationships?.field_image?.image_style_uri?.wide
    :
    nodeBlogPost?.relationships?.field_image?.image_style_uri?.wide

  const getTitle = () => {
    return isCategory
      ?
      'Categoria: ' + post?.title
      :
      isTag
      ?
      'Tag: ' + post?.title
      : post?.post_type && post.post_type === "user" ?  post?.title  + ' ' + post?.field_surname : post?.title
  }

  return(
    <section
      className={`image-box d-flex justify-content-center m-t-64 max-height-${height.replace("px", "")} ${hasButton ? 'has-button' : ''} overlay-${backgroundColor} ${className}`}
      style={{height: height + '!important', backgroundPosition: 'center'}}
      >
      <div
        className="image-box__background"
        style={{backgroundImage: `url(${backgroundImage})`, height: height + '!important'}}
      ></div>
      <div
        className={`${backgroundColor} image-box__overlay`}
        style={{height: height + '!important'}}
        ></div>
      <div className="image-box__content my-auto">
        <h1
          className="H1-Bold-C---white m-b-24"
        >{getTitle()}</h1>
        {post?.field_tagline &&
          <h5 className="H5-Medium-C---white m-b-56">
            {post?.field_tagline}
          </h5>
        }

        {
          summaryAsBody
          ?
          <h5 className="H5-Medium-C---white m-b-56">
          {post?.body.summary}
          </h5>
          :
          <h5
          className="H5-Medium-C---white m-b-56"
          dangerouslySetInnerHTML={{ __html: post?.body?.value }}
          />
          }

        {multipleButtons &&
          <div className="multiple-buttons">{multipleButtons}</div>
        }


        {buttonBanner &&
          <div className="row">
            <div className="container">
              <div className="col-12 button-cta align-center cccc">
                <ButtonModal
                  title={t(buttonBanner)}
                  backgroundimage={backgroundImage}
                  formId="visit_us_tramuntana"
                  modalTitle={t("Ets nou aquí?")}
                  modalDescription={t("Estem molt contents que hagis decidit unir-te a nosaltres en línia! Volem que sàpigues que tenim un lloc per al teu fill/a, que és benvingut/da aquí! Ens encantaria que et connectessis amb nosaltres i ajudar-te a guiar els propers passos en el seu camí educatiu.")}
                  modalFooter=""
                  className="Normal-Bold-L---bordeaux white p-r-0"
                />
              </div>
            </div>
          </div>
        }

        {anchorButton && anchorButtonText &&
          <div className="row">
            <div className="container">
              <div className="col-12 button-cta align-enter">
                <AnchorLink
                  to={anchorButton}
                  title={t(anchorButtonText)}
                  className="Normal-Bold-L---bordeaux white p-r-0"
                />
              </div>
            </div>
          </div>
        }

        {/*multipleButtons && multipleButtons.map((button, index) => {
            return(
              <React.Fragment>
                {buttonType === "anchor" ? <span key={index}>{button}</span> : <span key={index}>{button}</span> }
              </React.Fragment>
            )

        })*/}

        {buttonLinkBanner &&
          <div className="row">
            <div className="container">
              <div className="col-12 button-cta align-enter">
                <Link
                  to={Utils.removeMainLanguage(post?.field_cta?.url)}
                  className="Normal-Bold-L---bordeaux white p-r-0"
                >
                {buttonLinkBanner}
                </Link>
                <ButtonModal
                  title={t(buttonBanner)}
                  backgroundimage={backgroundImage}
                  formId="visit_us_tramuntana"
                  modalTitle={t("Ets nou aquí?")}
                  modalDescription={t("Estem molt contents que hagis decidit unir-te a nosaltres en línia! Volem que sàpigues que tenim un lloc per al teu fill/a, que és benvingut/da aquí! Ens encantaria que et connectessis amb nosaltres i ajudar-te a guiar els propers passos en el seu camí educatiu.")}
                  modalFooter=""
                  className="Normal-Bold-L---bordeaux white p-r-0 hide"
                />
              </div>
            </div>
          </div>
        }

      </div>
    </section>
  )
}

Banner.propTypes = {
  post: PropTypes.object,
  height: PropTypes.string,
  buttonBanner: PropTypes.string,
  backgroundColor: PropTypes.string,
  anchorButton: PropTypes.string,
  anchorButtonText: PropTypes.string,
  multipleButtons: PropTypes.array,
  buttonType: PropTypes.string,
  summaryAsBody: PropTypes.bool,
  taxonomyBackgroundImage: PropTypes.string,
  isCategory: PropTypes.bool,
  isTag: PropTypes.bool
}

Banner.defaultProps = {
  post: {},
  height: `420px`,
  buttonBanner: ``,
  backgroundColor: ``,
  anchorButton: ``,
  anchorButtonText: ``,
  multipleButtons: [],
  buttonType: ``,
  summaryAsBody: false,
  taxonomyBackgroundImage: ``,
  isCategory: false,
  isTag: false
}

export default Banner
